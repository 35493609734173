import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const config = require('../config');

const Template = ({ pageContext }) => {
  let url = `${pageContext.to}`;

  if (!url.startsWith('/') && !url.startsWith(config.siteUrl)) {
    url = `/${url}`;
  }

  if (!url.startsWith(config.siteUrl)) {
    url = config.siteUrl + url;
  }

  return (
    <Helmet>
      <link rel="canonical" href={url} />
      <meta charset="utf-8" />
      <meta httpEquiv="refresh" content={`0; url=${url}`} />
    </Helmet>
  );
};

Template.propTypes = {
  pageContext: PropTypes.object.isRequired, // eslint-disable-line
};

export default Template;
